/* Set up fonts */
/* @font-face {
  font-family: 'PT Sans Bold';
  src: url('./fonts/PT Sans Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PT Sans';
  src: url('./fonts/PT Sans.ttf') format('truetype');
}

@font-face {
  font-family: 'PT Sans Narrow';
  src: url('./fonts/PT Sans Narrow.ttf') format('truetype');
} */

@font-face {
  font-family: 'PT Sans Narrow Bold';
  src: url('./fonts/PT Sans Narrow Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('./fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Thin';
  src: url('./fonts/Roboto-Thin.ttf') format('truetype');
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
