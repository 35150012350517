@import '../colors';

.loginSignupPageBtn{
    width: 100%;
    border-radius: .5rem;
    height: 3rem;
}

.pageLink{
    color: $red-color;
    cursor: pointer;
}

.mainView{
    display: grid;
    grid-template-areas: 
        "title"
        "fields"
        "submit"
        "footer";
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    row-gap: .5rem;
    border: $light-grey 1px solid;
    border-radius: .75rem;
    box-shadow:0px 5px 16px 0px rgba(0,0,0,0.2);
    padding: 2rem 1rem;
    .title{
        grid-area: title;
        justify-self: center;
    }
    .formFields{
        grid-area: fields;
    }
    .submitArea{
        grid-area: submit;
    }
    .footer{
        grid-area: footer;
        justify-self: center;
    }
}
.formField, .pageLink{
    margin-bottom: .5rem;
}

.signInForm, .resetPassForm, .signUpForm{
    display: grid;
    grid-template-areas: 
        "fields"
        "link"
        "button";
    .formInputs{
        grid-area: fields;
    }
    .pageLink{
        grid-area: link;
        justify-self: right;
    }
    .formBtn{
        grid-area: button;
    }
}