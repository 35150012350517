@import '../colors';

#system-builder{
    max-width: 500px;
    margin: auto;
    // General field styling
    .sysField{
        margin-bottom: 1rem;
    }
    // Header row styling
    #header-row{
        display: grid;
        grid-template-columns: 8fr 2fr;
        grid-template-areas: "title iconBtn";
        align-items: center;
        margin-bottom: 1rem;
        h2{
            grid-area: title;
            margin-bottom: 0;
        };
        .headerBtn{
            grid-area: iconBtn;
            justify-self: end;
        }
        .icon{
            width: 1.5rem;
            height: 1.5rem;
        };
    };
    #submit-btns{
        margin-top: 1.5rem;
        button{
            height: 2rem;
            display: inline;
            min-width: 5rem;
        }
        button:nth-of-type(2) {
            margin-left: 1rem;
        }
    }
    
};

// Crit list 
div#critList{
    min-height: 10vh;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow:0px 5px 16px 0px rgba(0,0,0,0.1);
    margin-bottom: 1rem;
    .criteria:not(:last-child){
        border-bottom: 2px solid rgba(0, 0, 0, 0.1); 
    }

    div.criteria{
        margin-top: 0 .5rem;
        background-color: white;
        padding: 5px 10px;
    }
    #noCrit{
        text-align: left;
        padding-left: 10px;
        margin-top: 1.5rem;
    };
}

#stat-avg-crit {
    #stats-comp{
        display: grid;
        grid-template-areas: "stat1 stat2";
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        #stat1-div {
            grid-area: stat1;
        }
        
        #stat2-div {
            grid-area: stat2;
        }
    }
    .statField{
        margin: 1rem 0;
    }
    #filter-div {
        grid-area: filt;
    }
    
    #min-div {
        grid-area: min;
    }
    
    #max-div {
        grid-area: max;
    }
    // Divs with label in the border (Stat Avg Criteria) 
    .border-label {
        display: flex;
        position: relative;
        padding: 20px 10px;
        border: 1px solid #ced4da;
        min-height: 115px;
        text-align: center;
        // for when stat has been selected
        .stat-content{
            display: grid;
            grid-template-areas: "label edit";
            grid-template-columns: 9fr 1fr;
            gap: 5px;
            align-items: center;
            justify-items: center;
            width: 100%;
            .stat-lbl{
                grid-area: label;
                text-align: left;
                margin: 0;
            }
            .editIcon{
                grid-area: edit;
                width: 20px;
            }
        }
        // when no stat has been selected
        .add-stat{
            display: block;
            margin: auto;
            width:35px;
            height: auto;
        }
        .label-content {
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            padding: 0 10px;
            font-size: 1rem;
            white-space: nowrap;
        }
    }
}

#between-div{
    display: grid;
    grid-template-areas: "min max";
    gap: 1rem;

    #min-div{
        grid-area: min;
    }
    #max-div{
        grid-area: max;
    }
}

#systemForm{
    .switch-wrapper {
        display: flex;
        padding: 4px;
        background-color: $dark-grey;
        margin-bottom: 10px;
      }
      
      .switch-radio {
        flex-grow: 1;
      }
      
      .switch-radio input[type=radio] {
        display: none;
      }
      
      .switch-radio label {
        display: block;
        padding: 6px 8px;
        color: #F5F5F5;
        font-weight: bold;
        text-align: center;
        transition : all .4s 0s ease;
      }
      
      .switch-radio input[type=radio]:checked + label {
        background-color: #F5F5F5;
        color: #000;
        border-radius: 4px;
      }
}

