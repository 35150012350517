@use './pages/game';
@use 'assets';
@import './colors';

// 1400,1200,992,768,576
.container-fluid {
    padding-left: 0px !important;
    padding-right:  0px !important;
    // overflow-x: hidden;
}

div#mainContainer{
    position: relative;
    min-height: 100vh;
    display: grid; 
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 
        "navbar"
        "full-page"
        "footer";
    .main-nav{
        grid-area: navbar;
    }
    .full-page{
        grid-area: full-page;
        max-width: 100vw;
    }
}

.hidden-until-css-load {
    visibility: hidden;
}

// XXL Screens
@media screen and (min-width: 1440px){
    .page-section{
        padding: 70px 175px;
        width: 100%;
    }
}

// XL Screens
@media screen and (max-width: 1440px) and (min-width: 1200px){
    .page-section{
        padding: 60px 175px;
    }
}

// L Screens
@media screen and (max-width: 1200px) and (min-width: 992px){
    .page-section{
        padding: 50px 50px;
    }
}



// S and XS Screens
@media screen and (max-width: 768px){
    .page-section{
        padding: 25px 15px;
    }
    h1{
        font-size: 2rem;
    }
    h3{
        font-size: 1.7rem;
    }
}

// M Screens
@media screen and (max-width: 992px) and (min-width: 768px){
    .page-section{
        padding: 50px 50px;
    }
    h1{
        font-size: 2.5rem;
    }
    h3{
        font-size: 2rem;
    }
}

h1, h2, h3, h4, h5, h6{
    font-family: "PT Sans Narrow Bold";
}

p, button{
    font-size: 1rem;
}

.errMsg{
    color: $red-color
}

.pageLink{
    color: $red-color;
    cursor: pointer;
}