/* UPCOMING GAME PAGE */
#upcoming-game{
  display: grid;
  grid-template-areas: 'header'
                       'systems'
                       'tables';
  row-gap: 1rem;
  #game-header-div{
    grid-area: header;
    display: grid;
    grid-template-areas: 'game-label analyzer'
                         'info info';
    
    align-items: center;
    margin-bottom: 1rem;
    #game-label{
      grid-area: game-label;
      margin-bottom: 0px;
    }
    #analyzer-link{
      grid-area: analyzer;
      font-size: 1.2rem;
      font-weight: bold;
    }
    #info-label{
      grid-area: info;
      font-size: .9rem;
      font-weight: bold;
      display: grid;
      grid-template-areas: 'date sport';
      margin-top: .5rem;
      width: max-content;
      align-items: center;
      #info-date {
        grid-area: date;
        border-right: 1px solid gray; /* Adds the vertical divider */
        padding-right: 1.5rem;        /* Adjusts spacing to account for the border */
      }
      
      #info-sport {
        grid-area: sport;
        padding-left: 1.5rem;         /* Ensures consistent spacing on the left */
      }
    }
  }
  #matching-systems{
    grid-area: systems;
  }
  #data-tables{
    grid-area: tables;
    display:grid;
    #stat-avgs{
      grid-area: stats;
    } 
    #betting-lines{
      grid-area: betlines;
    }
  }
}

#bet-select{
    margin-bottom: 2rem;
}


.oddsTable, .statsTable{
    /* Set default styles for the table */
    table {
        border-collapse: collapse;
        width: 100%;
        text-align: left;
        font-size: 1rem;
        line-height: 1.5;
      }
  
      /* Set styles for the table header */
      th {
        background-color: #f2f2f2;
        padding: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }
  
      /* Set styles for the table rows */
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
  
      tr:nth-child(odd) {
        background-color: #ffffff;
      }
  
      /* Set styles for the table cells */
      td {
        padding: 10px;
        border: 1px solid #ddd;
      }
}


// media queries
// Mobile
@media screen and (max-width: 767px){
  #analyzer-link{
    justify-self: end;
  }
  
  tr{
      td:first-of-type{
          width: max-content;
      }
      td{
          min-width: 60px;
          height: 48px;
      }
  }
  
  #data-tables{
    grid-template-areas: 'stats'
                         'betlines';
    row-gap: 1rem;

    #stat-avgs, #betting-lines{
      max-width: max-content;
      justify-self: center;

    }
  }
}

// M Screens
@media screen and (min-width: 768px) and (max-width: 992px) {
  #upcoming-game{
    #game-header-div{
      grid-template-columns: max-content auto;
      column-gap: 2rem;
    }
    #data-tables{
      grid-template-areas: 'stats betlines';
      grid-template-columns: max-content auto;

      #stat-avgs, #betting-lines{
        width: max-content;
      }
      #betting-lines{
        justify-self: end;
      }
    }
  }
}

// Large screens
@media screen and (min-width: 992px){
  #upcoming-game{
    grid-template-areas: 'header'
                         'systems'
                         'tables';

    #game-header-div{
      grid-template-columns: max-content auto;
      column-gap: 2rem;
    }
    #data-tables{
      grid-template-areas: 'stats betlines';
      grid-template-columns: max-content auto;
      column-gap: 2.5rem;

      #stat-avgs, #betting-lines{
        width: max-content;
      }
    }
  }
}