@import '../utils/variables.scss';
@import '../colors';

// Global Styling
div#componentBody{
    box-shadow:0px 5px 16px 0px rgba(0,0,0,0.2);
    display: grid;
    grid-template-rows: [head] 5rem [disp] auto;
    #header{
        grid-row: head;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5rem;
        background: #F9FBFC;
        position: relative;
        h6{
            float: right;
        }
        h6, h4{
            margin-bottom: 0;
        }
        #ellipsis{
            height: 25px;
            width: auto;
        }
        button#followBtn{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .25rem;
            border: none;
            background-color: transparent;
            color: black;
            img#follow{
                height: 1.5rem;
            }
            span{
                font-weight: bold;
            }
        }
        img#following{
            height: 2rem;
        }
        ul{
            position: absolute;
            padding-left: 0;
            top: 100%;
            right: 0;
            z-index: 10;
            width: 200px;
            background-color: white;
            box-shadow: 0px 5px 16px 0px rgba(0,0,0,0.2);
            min-height: 5rem;
            li{
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                padding-left: 1rem;
                list-style: none;
                height:2.5rem;
                img#edit, img#share{
                    height: 20px;
                    float: right;
                    padding-right: 1rem;
                }
                img#delete{
                    height: 30px;
                    float: right;
                    padding-right: 0.75rem;  
                }
                span{
                    float: left;
                }
                &:hover, &:active{
                    background-color: #F9FBFC;
                }
            }
        }
    }
    .nav-tabs{
        grid-row: disp;
        margin-bottom: 1rem;

        .nav-link{
            color: black;
            background-color: #F9FBFC;
        }
        .nav-link.active{
            background-color: white;
        }
    }
}

div.sysView {
    border: 1px solid #dcdfe0;
    border-top: none;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto auto auto auto 1fr;
    
    > div{
        padding: 1rem;
    }
    
    #header {
        grid-area: header;
    }
    
    .bet-div {
        grid-area: bet;
    }
    
    .record-div {
        justify-self: end;
        grid-area: record;
    }
    
    .crit-div {
        grid-area: crit;
    }
    
    .win-div {
        grid-area: win;
    }
    
    .roi-div {
        justify-self: end;
        grid-area: roi;
    }
    #graph_div {
        grid-area: graph;
    }
    .win-div, .roi-div {
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75px;
        width: 75px;
        div{
            text-align: center;
        }
    } 
    .win-div, .roi-div {
        position: relative;
    }
    .green{
        border:1px solid #dcdfe0;
        background-color: #ecfdf3;
        color: #15b86c;
    }
    .red{
        border:1px solid #dcdfe0;
        background-color: #fbe9eb;
        color: $lineleader-red;
    }
    .up-caret{
        position: absolute;
        top: -18px;
        height: 18px;
    }
    .down-caret{
        position: absolute;
        bottom: -18px;
        height: 18px;
    }
    div.carousel-indicators{
        position: absolute;
        top:-20px;
        > button{
            background-color: $lineleader-red !important;
            border-radius: .1rem;
        }
    }
    .bet-div, .record-div, .crit-div{
        h5:after{
            border-bottom: 2.5px solid #dcdfe0;
            content: '';
            display: block;
            width: 1.5rem;
        }
    }
}

#resultTbl{
    td, th{
        text-align: center;
        vertical-align: middle;
    }
    .topText, .bottomText{
        display: block;
    }
    td.bet-L{
        background-color: rgba($lineleader-red, 0.80);
    }
    td.bet-W{
        background-color: rgba(21, 184, 108, 0.80);
    }
    thead, tbody{
        border-top: none;
        display: block;
        max-height: 500px;
        overflow-y: auto;

        tr{
            display: table;
            width: 100%;
            table-layout: fixed; 
        }
    }

}
// Media Queries
// Mobile and Tablet
@media screen and (max-width: 992px){
    div.sysView{
        grid-template-areas:
        "header header header header header header header header header header header header"
        "bet bet bet bet bet bet record record record record record record"
        "crit crit crit crit crit crit crit crit crit crit crit crit"
        "win win win win win win roi roi roi roi roi roi"
        "graph graph graph graph graph graph graph graph graph graph graph graph";
        > div{
            padding: 0.5rem 1rem ;
        }
        #header{
            margin-bottom: 0;
        }        
    }

    .win-div, .roi-div {
        margin-bottom: 30px;
    }
    #resultTbl{
        font-size: 0.8rem;
        .gameCol, .dateCol{
            width: 25%;
        }
        .betCol{
            width: 20%
        }
        .oddsCol, .resCol{
            width: 15%;
        }

    }    
}
// Laptop and Desktop Screens
@media screen and (min-width: 993px){
    div.sysView{
        grid-template-areas:
        "header header header header header header header header header header header header"
        "bet bet record record record roi graph graph graph graph graph graph"
        "crit crit crit crit crit win graph graph graph graph graph graph";
    }
    #graph_div{
        align-self: center;
    }

}