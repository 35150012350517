
@import '../colors';
// Login and signup buttons
.loginSignupBtn{
    width: 8rem;
    height: 3rem;
    margin-right:.5rem;
    margin-top: 1rem;
}

#section-1{
    padding-bottom: 0;
    img#hero{
        z-index:-1;
    }
}
#section-2{
    z-index: 2;
}

#strikethrough{
    position: relative;
    img{
        position: absolute;
        top: 38%;
        left: 0;
        width: 100%; 
    }
}

// S and XS Screens
@media screen and (max-width: 768px){
    #section-1{
        text-align: center;
        position: relative;
        p{
            max-width: 100%;
            margin: auto;
        }
        #sec1-content{
            display: inline-block;
            width: 100%;
            #red-text{
                position: relative;
                #underline{
                    position: absolute;
                    bottom: -.5rem;
                    right: 0;
                    width: 100%;
                }
                color: #DA1E34;
            }
            img#hero{
                bottom: 0;
                width: 100%;
            }
        } 
    }
    #section-2{
        text-align: center;
    }
    #sec2-btns{
        display: none;
    }
}

// M Screens
@media screen and (max-width: 992px) and (min-width: 768px){
    #section-1{
        text-align: center;
        position: relative;
        p{
            max-width: 80%;
            margin: auto;
        }
        #sec1-content{
            display: inline-block;
            width: 100%;
            #red-text{
                position: relative;
                #underline{
                    position: absolute;
                    bottom: -.5rem;
                    right: 0;
                    width: 100%;
                }
                color: #DA1E34;
            }
            img#hero{
                bottom: 0;
                width: 100%;
            }
        } 
    }
    #section-2{
        text-align: center;
    }
}

// L Screens
@media screen and (max-width: 1200px) and (min-width: 992px){
    #section-1{
        height: 720px;
        position: relative;
        p{
            max-width: 400px;
        }
        #sec1-content{
            display: inline-block;
            width: 70%;
            #red-text{
                position: relative;
                #underline{
                    position: absolute;
                    bottom: -.5rem;
                    right: 0;
                    width: 100%;
                }
                color: #DA1E34;
            }
            img#hero{
                position:absolute;
                right:0px;
                bottom: -5%;
                height: 70%;
            }
        } 
    }
}

// XL Screens
@media screen and (max-width: 1440px) and (min-width: 1201px){
    #section-1{
        height: 720px;
        position: relative;
        p{
            max-width: 400px;
        }
        #sec1-content{
            display: inline-block;
            width: 80%;
            #red-text{
                position: relative;
                #underline{
                    position: absolute;
                    bottom: -.5rem;
                    right: 0;
                    width: 100%;
                }
                color: #DA1E34;
            }
            img#hero{
                position:absolute;
                right:0px;
                bottom: 0;
                height: 70%;
            }
        } 
    }
}

// XXL Screens
@media screen and (min-width: 1440px){
    #section-1{
        height: 720px;
        position: relative;
        p{
            max-width: 400px;
        }
        #sec1-content{
            display: inline-block;
            width: 70%;
            #red-text{
                position: relative;
                #underline{
                    position: absolute;
                    bottom: -.5rem;
                    right: 0;
                    width: 100%;
                }
                color: #DA1E34;
            }
            img#hero{
                position:absolute;
                right: 5%;
                bottom: 0;
                height: 80%;
            }
        } 
    }
}

// Section 2
#section-2{
    width: 100%;
    padding-top: 3rem;
    .row{
        margin-bottom: 3rem;
    }
    position: relative;
    background-color: $light-grey;
    .right-point-p1{
        text-align: left;
        color: white;
        padding: 1rem;
        .step{
            width: 90%;
            .step-icon{
                position: relative;
                width: 3rem;
                height: 3rem;
                background-color: #DA1E34;
                margin-bottom: .8rem;
                img{
                    position: absolute;
                    top: 25%;
                    left: 25%;
                }
            }
            .show-more{
                position: absolute;
                background-color: transparent;
                border: none;
                color: #ffffff;
                bottom: 15px;
            }
        }
    }
}

// Rectangle Shapes
.right-point-p1{
    display: inline-block;
    position: relative;
    z-index: 2;
    background-color: $dark-grey;
}
.right-point-p2{
    position:absolute;
    display: inline-block;
    z-index: 1;
    background-color: $red-color;
}
.right-point-col{
    position: relative;
    margin-bottom: 25px;
}
// XXL Screens
@media screen and (min-width: 1440px){
    .right-point-p1, .right-point-p2{
        width: 350px;
        height: 400px;
    }
    .right-point-p2{
        transform: translate(-335px, 15px)
    }
}

// XL Screens
@media screen and (max-width: 1440px) and (min-width: 1201px){
    .right-point-p1, .right-point-p2{
        width: 275px;
        height: 400px;
    }
    .right-point-p2{
        transform: translate(-260px, 15px)
    }
}

// L Screens
@media screen and (max-width: 1200px) and (min-width: 992px){
    .right-point-p1, .right-point-p2{
        width: 275px;
        height: 400px;
    }
    .right-point-p2{
        transform: translate(-260px, 15px)
    }
}

// M Screens
@media screen and (max-width: 992px) and (min-width: 768px){
    .right-point-p1, .right-point-p2{
        width: 225px;
        height: 350px;
    }
    .right-point-p2{
        transform: translate(-215px, 10px)
    }
}

// S Screens
@media screen and (max-width: 768px){
    .right-point-p1, .right-point-p2{
        width: 250px;
        height: 350px;
    }
    .right-point-p2{
        transform: translate(-235px, 10px)
    }
}