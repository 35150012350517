@import '../utils/variables.scss';
@import '../colors';


/* Styles for my systems page */
.top_row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "dropdown sport create";
    margin-bottom: 1rem;
    align-items: center;
    #sysViewSelect{
        grid-area: dropdown;
        padding: 0;
        font-size: 1.5rem;
        color: black;
        font-weight: bold;
        .system-view{
            height: 45px;
            width: 250px;
            text-align: left;
            padding: 0 .5rem;
            font-size: 25px;
            color: black;
            background-color: white;
        }
        .system-view:focus{
            box-shadow: 0px 5px 16px 0px rgba(0,0,0,0.2);
        }
    }
    #sysViewSelect + div{
        height: auto;
    }
    
    #sportSelection{
        grid-area: sport;
        max-width: 500px;
        color: black;
        padding-left: 0;

        #sportLabel{
            .labelIcon{
                max-width: 2rem;
                height: auto;
            }
        }
    }
    
    .sportSel{
        align-self: center;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-areas: "icon text";
        span{
            align-self: center;
            grid-area: text;
            padding-left: 0.5rem
        }
        .sportsIcon{
            grid-area: icon;
            max-width: 2rem;
            height: auto;
        }
    }

    #plusIcon{
        grid-area: create;
        height: 2rem;
        width: auto;
        align-self: center;
        justify-self: end;
    }
}

#createSysModal{
    .sportSel:hover{
        background-color: $light-grey;
    }
    .sportSel{
        span{
            align-self: center;
            grid-area: text;
        }
        .sportsIcon{
            max-width: 2rem;
            height: auto;
        }
    }
}


.customPageItem.active{
    .page-link{
        background-color: #F9FBFC;
        color: black;
        border-color: black;
        z-index: 10;
    }
    .page-link:focus{
        box-shadow: none;
        background-color: #F9FBFC;
    }
}

.customPageItem{
    .page-link{
        color: black;
    }
    .page-link:focus{
        box-shadow: none;
    }
}



/* system preview */
div.systemPrev{
    margin-bottom: 1.5rem;
    border: 1px solid #dcdfe0;
    box-shadow:0px 5px 16px 0px rgba(0,0,0,0.2);
    div, canvas{
        padding: 0rem 1rem;
    }
    .sysHeader{
        display: grid;
        align-items: center;
        grid-template-columns: 4fr 1fr;
        grid-template-areas: "sysName viewBtn";
        width: 100%;
        height: 5rem;
        background: #F9FBFC;
        margin-bottom: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        h4{
            grid-area: sysName;
            margin-bottom: 0;
        }
        svg{
            grid-area: viewBtn;
            text-align: right;
            border: none;
            background-color: transparent;
            align-self:center;
            justify-self: end;
            height: 1.5rem;
            width: auto;
        }
        
    }

    .roiChart{
        max-height: 100px;
    }
    .row{
        padding: 1rem;
    }

    .bet-div, .record-div, .crit-div{
        margin-bottom: 1rem;
        h5:after{
            border-bottom: 2.5px solid #dcdfe0;
            content: '';
            display: block;
            width: 1.5rem;
        }

    }
    .bet-div, .record-div{
        display: inline-block;
        width: 50%;
    }
    .record-div{
        float: right;
        width: auto;
    }
    .win-div, .roi-div {
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75px; /* adjust this to desired height */
        width: 75px; /* adjust this to desired width */
        div{
            text-align: center;
        }
    }  
    .win-div, .roi-div {
        position: relative;
        margin-bottom: 30px;
    }
    .win-div {
        float: left;
    }
    .roi-div {
        float: right;
    }
    .green{
        border:1px solid #dcdfe0;
        background-color: #ecfdf3;
        color: #15b86c;
    }
    .red{
        border:1px solid #dcdfe0;
        background-color: #fbe9eb;
        color: $lineleader-red;
    }
    .up-caret{
        position: absolute;
        top: -18px;
        height: 18px;
    }
    .down-caret{
        position: absolute;
        bottom: -18px;
        height: 18px;
    }
}

// Grid
.systemPrev{
    display: grid;
    
    .sysHeader {
        grid-area: header;
    }
    
    .bet-div {
        grid-area: bet;
    }
    
    .record-div {
        justify-self: end;
        grid-area: record;
    }
    
    .crit-div {
        grid-area: crit;
    }
    
    .win-div {
        grid-area: win;
    }
    
    .roi-div {
        justify-self: end;
        grid-area: roi;
    }
    
    .roiChart {
        grid-area: graph;
    }
}


// Media Queries
// Mobile and Tablet
@media screen and (max-width: 992px){
    div.systemPrev{
        grid-template-columns: repeat(12, 1fr);

        grid-template-areas:
        "header header header header header header header header header header header header"
        "bet bet bet bet bet bet record record record record record record"
        "crit crit crit crit crit crit crit crit crit crit crit crit"
        "graph graph graph graph graph graph graph graph graph graph graph graph"
        "win win win win win win roi roi roi roi roi roi";

        grid-template-rows: auto auto auto auto auto 1fr;
        .roiChart{
            min-width: 100%;
            canvas{
                min-width: 100%;
            }
        }
    }
    .top_row{
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "dropdown create"
                             "sport sport";
    }   
}

// Laptop and Desktop Screens
@media screen and (min-width: 993px){
    div.systemPrev{
        grid-template-columns: repeat(12, 1fr);

        grid-template-areas:
        "header header header header header header header header header header header header"
        "bet bet bet record record record graph graph graph graph roi win"
        "crit crit crit crit crit crit graph graph graph graph roi win";
    
        grid-template-rows: auto auto auto  1fr;
        .roiChart, .win-div, .roi-div{
            align-self: center;
        }
        .roiChart{
            min-width: 100%;
            canvas{
                min-width: 100%;
            }
        }
    }

}