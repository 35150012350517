@import '../colors';

// grid
.matches-page{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        "header header header"
        "games games games";
    div.top_row{
        grid-area:  header;
        min-width: 100%;
        margin-bottom: 1rem;
    }
    div.gamesList{
        grid-area: games;
        column-gap: 1rem;
        display:grid;
    }
}
// DO NOT NEST 
.gameItem-mlb{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    background: #FFFFFF;
    border-radius: 8px;
    // overflow-y: visible;
    grid-template-areas:
    "matches matches matches"
    "sport sport sport"
    "away at home";

    filter: drop-shadow(0px 8px 8px rgba(99, 99, 99, 0.2));
    margin-bottom: 30px;
    max-width: 375px;
    max-height: 200px;
    .sport {
        grid-area: sport;
        text-align: left;
    }
    .home {
        grid-area: home;
    }    
    .at {
        grid-area: at;
    }
    .away {
        grid-area: away;
    }
    .sport, .away, .home, .at{
        padding: 10px;
        justify-self: center;
        align-self: center;
        text-align: center;
    }
    span.matchesBadge{
        grid-area: matches;
        background-color: $red-color;
        color: white;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        text-align: center;
    }
}

.gameItem-nfl{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    background: #FFFFFF;
    border-radius: 8px;
    // overflow-y: visible;
    grid-template-areas:
    "matches matches matches"
    "info info info"
    "away at home";

    filter: drop-shadow(0px 8px 8px rgba(99, 99, 99, 0.2));
    margin-bottom: 30px;
    max-width: 375px;
    max-height: 125px;
    min-height: 125px;
    .info {
        display: flex;
        grid-area: info;
        align-items: center;
        justify-content: center;
        h6{
            margin: 0;
            display: inline;
            padding-left: 1rem;
        }
    }
    .home {
        grid-area: home;
    }    
    .at {
        grid-area: at;
    }
    .away {
        grid-area: away;
    }
    .sport, .away, .home, .at{
        padding: 10px;
        justify-self: center;
        align-self: center;
        text-align: center;
    }
    span.matchesBadge{
        grid-area: matches;
        background-color: $red-color;
        color: white;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        text-align: center;
    }
}


// media queries
// Mobile
@media screen and (max-width: 767px){
    div.gameItem{
        min-width: 90%;
        max-width: 90%;
        justify-self: center;
    }
}

// Tablet
@media screen and (min-width: 768px) and (max-width: 992px){
    div.top_row{
        text-align: center;
    }
    div.gamesList{
        grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
        div.gameItem{
            min-width: 350px;
            justify-self: center;
        }
    }

}

// Laptop and Desktop Screens
@media screen and (min-width: 993px){
    div.gamesList{
        align-self: start;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        div.gameItem{
            min-width: 350px;
            justify-self: center;
        }
    }

}