// Notification Settings 
div#noti-settings{
    display: grid;
    grid-template-areas:"header" 
                        "devCard"
                        "allCard";
    #header{
        grid-area: header;
        justify-self: left;
    }
    .card{
        max-width: 450px;
        .card-body{
            display:grid;
            row-gap: 1rem;
            column-gap: 1rem;
            grid-template-columns: 1fr 1fr;
            grid-template-areas:"field field"
                                "cancelBtn subBtn";
            div.field{
                grid-area: field;
                .errMsg{
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                }
            }
            .primaryBtn{
                grid-area: subBtn;
            }
            .secondaryBtn{
                grid-area: cancelBtn;
            }
        }
    }

    #devCard{
        grid-area: devCard;
    }
    #allCard{
        grid-area: allCard;
    }
}