@import './colors';
@keyframes contract {
    100% { transform: scale(0.9); }
}

// Buttons 
.primaryBtn{
    background-color: $dark-grey;
    color: white;
    &:active &:hover{
        border: 1px solid $dark-grey;
        background-color: transparent;
        color: black;
    }
}

.secondaryBtn{
    background-color: transparent;
    color: black;
}

.secondaryBtn, .primaryBtn{
    transition: transform 0.3s ease;
    border-radius: .25rem;
    border: 1px solid $dark-grey;
    &:active{
        animation: contract 0.3s forwards;
    }
    &:hover{
        box-shadow: 0 0 .2rem $dark-grey;
    }
}

.primaryBtn:disabled, .secondaryBtn:disabled{
    transition: none;
    opacity: 0.75; 
    cursor: not-allowed;

    &:hover{
        box-shadow: none;
    }
    &:active{
        animation: none;
    }
}

// svg buttons 
svg.button{
    transition: transform 0.3s ease;
    &:hover{
        scale: (1.1);
    }
    &:active{
        animation: contract 0.3s forwards;
    }
}

// LINKS
a.reg-link{
    color: $red-color;
    cursor: pointer;
}