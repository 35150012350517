@import '../colors';

#analyzer-games-menu{
    display: grid;
    grid-template-areas: "title"
                         "btns";
    #title{
        grid-area: title;
        font-size: .9rem;
        font-weight: bold;
        margin-bottom: .2rem;
    }
    #sub-menu-btns{
        grid-area: btns;
    }
}

#sub-menu-btns{
    :first-child{
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }
    :last-child{
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }
    button{
        border: 1.5px solid $dark-grey;
        min-width: 3rem;
        background-color: white;
    }
    button.selected{
        background-color: $dark-grey;
        color: white;
    }
}

#game-analyzer-menu{
    button{
        border-radius: .25rem;
        border: 1.5px solid $dark-grey;
        background-color: $light-grey;
        display:flex;
        align-items: center;
        width: auto;
        padding: .2rem 1rem;
        #back-icon{
            height: .7rem;
        }
    }
}

#chart-div{
    #chart-container{
        #chart-label, #legend-container{
            margin-bottom: .75rem;
        }
        #chart-label{
            display: block;
            text-align: center;
            font-size: .9rem;
            font-weight: bold;
        }
        #legend-container ul{
            justify-content: center;
        }
    }
}

#overview-div{
    grid-area: overview;
    display: grid;
    grid-template-areas: 'labels sets';
    grid-template-columns: max-content auto;
    margin-bottom: 1.5rem;
    padding-bottom: .75rem;
    span, .num-game{
        display: flex;
        align-items: center;
    }
    #split, .num-games{
        margin-bottom: .75rem;
        height:50px;
    }
    .num-games{
        align-content: center;
        position: relative;
        width: 100%;
        text-align: center;
    }
    .num-games::after { // Vertical divider
        content: '';
        position: absolute;
        width: 90%; 
        height: 1px; 
        bottom: 5%;
        left: 5%; 
        background-color: $dark-grey; 
        visibility: visible;
        box-shadow:0px 5px 10px 0px rgba(0,0,0,0.2);
    }

    #resultLabels{
        grid-area: labels;

        span{
            font-size: .8rem;
            font-weight: bold;
            padding: .5rem 0;
        }
        span#split{
            padding: 0 0;
        }
        #inning-labels{
            grid-template-areas: 'split'
                                 'team'
                                 'record'
                                 'hitrate'
                                 'avg';
            border: 1.5px solid transparent;
        }
        #totals-labels{
            grid-template-areas:'split'
                                'record'
                                'hitrate'
                                'team'
                                'avg';
        }
        #runline-labels{
            grid-template-areas:'split'
                                'record'
                                'hitrate'
                                'avg';
        }
        #inning-labels, #totals-labels, #runline-labels{
            display: grid;
            height:100%;
            #split{
                grid-area: split;
            }
            #team-label{
                grid-area: team;
            }
            #record-label{
                grid-area: record;
            }
            #hitrate-label{
                grid-area: hitrate;
            }
            #avg-label{
                grid-area: avg;
            }

            #team-label, #record-label, #hitrate-label, #avg-label{
                height: 40px;
            }
        }
    }
    #resultSets{
        grid-area: sets;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 2rem;

        span{
            width: 100%;
            justify-content: center;
        }

        div.selected{
            background-color: white;
        }

        .inning-overview, .totals-overview{
            display: grid;
            grid-template-areas: 'num-games num-games'
                                 'data data';
            grid-template-rows: max-content max-content;
            .num-games{
                grid-area: num-games;
            }
            .inning-data{
                grid-area: data;
                display: grid;
                grid-template-areas: 't1 t2';
                .team-div:first-child{
                    grid-area: t1;
                    position: relative;
                }
                .team-div:first-child::after { // Vertical divider
                    content: '';
                    position: absolute;
                    width: 2px; 
                    height: 95%; 
                    bottom: 0;
                    left: 100%; 
                    background-color: $light-grey; 
                    visibility: visible;
                }
              
                .team-div:last-child{
                    grid-area: t2;
                }
                .team-div{
                    display: grid;
                    grid-template-areas: 'team'
                                         'record'
                                         'hitrate'
                                         'avg';
                    width: 100%;
                    height: 100%;
                    justify-items: center;
                    .team-data{
                        grid-area: team;
                    }
                    .record-data{
                        grid-area: record;
                    }
                    .hitrate-data{
                        grid-area: hitrate;
                    }
                    .avg-data{
                        grid-area: avg;
                    }  
                }
            }
            .totals-data{
                grid-area: data;
                display: grid;
                grid-template-areas: 'record record'
                                     'hitrate hitrate'
                                     't1 t2';
                justify-items: center;

                .record-data{
                    grid-area: record;
                }
                .hitrate-data{
                    grid-area: hitrate;
                }
                .team-div:nth-child(3) { // First .team-div as it is the third child
                    grid-area: t1;
                    position: relative;
                }
                .team-div:nth-child(3)::after { // Vertical divider
                    content: '';
                    position: absolute;
                    width: 2px; 
                    height: 80%; 
                    bottom: 10%;
                    left: 100%; 
                    background-color: $light-grey; 
                    visibility: visible;
                }
                .team-div:last-child{
                    grid-area: t2;
                }
                .team-div{
                    display: grid;
                    grid-template-areas: 'team'
                                         'avg';
                    width: 100%;
                    justify-items: center;
                    .team-data{
                        grid-area: team;
                    }
                    .avg-data{
                        grid-area: avg;
                    }  
                }
            }
        }
        .runline-overview{
            display: grid;
            grid-template-areas: 'num-games'
                                 'data';
            grid-template-rows: max-content max-content;

            .runline-data{
                grid-area: data;
                display: grid;
                grid-template-areas: 'record'
                                     'hitrate'
                                     'avg';
                justify-items: center;
            }
        }
        .runline-overview, .totals-overview, .inning-overview{
            background-color: $light-grey;
            
            .inning-data, .totals-data, .runline-data {
                // cell dividers for data
                span {
                    position: relative;
                    &:not(:last-child)::after {
                        content: '';
                        position: absolute;
                        width: 100%; 
                        height: 2px; 
                        bottom: 0px;
                        left: 0; 
                        background-color: $light-grey; 
                        visibility: visible;
                    }

                }
            }
            span{
                padding: .5rem .5rem;
                font-size: .9rem;
                height: 40px
            }
            .record-data{
                grid-area: record;
            }
            .hitrate-data{
                grid-area: hitrate;
            }
            .avg-data{
                grid-area: avg;
            }
        }
    }
}
// Mobile
@media screen and (max-width: 768px){
    #game-anayzler{
        display: grid;
        padding:5%;
        grid-template-areas: 
            'menu'
            'chart'
            'overview';
        #game-analyzer-menu{
            grid-area: menu;
            button{
                margin-bottom: 1rem;
                #back-icon{
                    height: .7rem;
                    margin-right: .5rem;
                }
            }

            select{
                max-width: 90vw;
            }
            select, #game{
                margin-bottom: 1rem;
            }
        }
    
        #analyzer-games-menu{
            visibility: hidden;
        }
    
        #chart-div{
            grid-area: chart;
            height: max-content;
            margin: 1rem 0;
            max-width: 90vw;
        }
        #overview-div{
            grid-area: overview;
            #resultSets{
                overflow-x: scroll;
                .inning-overview, .totals-overview, .runline-overview{
                    min-width: 140px;
                    border: 1.5px solid $light-grey;
                    border-radius: .25rem;
                    box-shadow:0px 2.5px 8px 0px rgba(0,0,0,0.2);
                }
            }
            #resultLabels, #resultSets{
                margin-top: 1rem;
                padding: 10px 10px;
            }
            #resultLabels{
                margin-right: 14px;
            }
        }
        #placeholder-div{
            position: relative;
            margin: 5rem auto;
        }
    }

}

// Tablet and above
@media screen and (min-width: 768px){
    #game-anayzler{
        display: grid;
        grid-template-areas: 
            'menu sub-menu'
            'menu chart'
            'menu overview';
        grid-template-columns: max-content auto;
        grid-template-rows: 6rem min-content auto;
        height: 100%;

        #game-analyzer-menu{
            grid-area: menu;
            border-right: .2rem solid $light-grey;
            padding: 1rem;
            min-width: 192px;
            max-width: 250px;
            button{
                #back-icon{
                    height: .7rem;
                    margin-right: .5rem;
                }
            }
            select, #game, button{
                margin-bottom: 1rem;
            }
        }
    
        #analyzer-games-menu{
            grid-area: sub-menu;
            border-bottom: .2rem solid $light-grey;
            padding: 20px 48px;
        }

    
        #chart-div{
            grid-area: chart;
            padding: 32px 48px;
            max-width: 1000px;
            overflow-y: auto;
            align-self: start;
            height: auto;
            #chart-container{
                padding: 5px 10px;
                box-shadow:0px 5px 10px 0px rgba(0,0,0,0.2);
                border-radius: .25rem;
            }
        }
        
        #overview-div{
            max-width: 1000px;
            padding: 32px 48px;
            overflow: auto;
            align-self: start;
            border-top: .2rem solid $light-grey;
            #resultLabels, #resultSets{
                min-height: max-content;
                min-width: max-content;
            }
            .runline-overview, .totals-overview, .inning-overview{
                border: 1.5px solid $light-grey;
                border-radius: .25rem;
                box-shadow:0px 5px 16px 0px rgba(0,0,0,0.2);
            }

            #resultLabels{
                margin-right: 28px;
            }
        }
    }

    #placeholder-div{
        position: relative;
        top: 10rem;
        left: 10rem;
    }
}