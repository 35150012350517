@import './colors';

// Account Dropdown
.dropdown-menu-account{
    height: 100px;
    a.dropdown-item{
        display: block;
        margin-top: 5px;
    }
    a.active{
        background-color: white;
        color: black;
    }
    a:hover{
        color: black;
    }
}
#account-dropdown::after{
    display: none !important;
}
#account-dropdown:hover, #account-dropdown:focus{
    color: black;
}

.dropdown-menu-sport-sel{
    height: 140px;
}

// Main Nav
.main-nav{
    height: 6rem;
    background-color: #FFFFFF !important;
    width: 100vw !important;
    border-bottom: .2rem solid #F9F9F9;
    .navbar-brand{
        margin-left: 10%;
    }
    .offcanvas-body, .offcanvas,.links{
        border: none;
        .nav-icon{
            margin-right: .25rem;
        }
    }

    .links{
        display: grid;
        font-size: 1.3rem;
        a, div.nav-item{
            position: relative;
            color: black;
            text-decoration: none;
        }
        a.nav-link::after, a.dropdown-item::after{
            content: '';
            position: absolute;
            width: 0;
            height: 2px; 
            bottom: -5px; 
            left: 0;
            background-color: #000; 
            visibility: hidden;
            transition: all 0.3s ease-in-out;
        }
        a.nav-link:hover::after, a:focus::after, a.active::after, a.dropdown-item:hover::after{
            visibility: visible;
            width: 50%; 
            left: 15%;
        }
        #account-dropdown:hover::after, #account-dropdown:focus::after, #account-dropdown.active::after, #account-dropdown::after{
            border: none !important;
            visibility: hidden;
        }
        a.dropdown-item:hover{
            background-color: white;
        }
        // align links - set display to flex 
        .navLinks{
            display: flex;

        }
    }

    #menuBtn{
        margin-right: 1rem;
        border: none;
        .navbar-toggler-icon{
            fill: black;
        }
    }
}

// Login and signup buttons
.loginSignupBtn{
    margin-bottom: 1rem;
    display: inline-block;
    width: 7rem;
    height: 2.5rem;
    margin:0 .5rem;
}

#dotBadge{
    display: inline-block;
    left: 5px;
    top: -15px;
    position: relative;
    width: .5rem;
    height: .5rem;
    border-radius: 45%;
    background-color: red;
}

#footer{
    display: grid;
    height:75px;
    .navLink{
        align-items: center;
        font-size: .8rem;
        color: white;
    }
    grid-template-columns: 20rem 1fr;
    background-color: $dark-grey;
    #left-footer{
        display: flex;
        align-items: center;
        #logo{
            height: 2rem;
            padding-left: 2rem;
            align-self: center;
        }
    } 
    #socials {
        display: flex;
        align-items: center;
        justify-content: end;
        .navLink {
            margin-left: .2rem; // Adjust spacing between social icons as needed

            &:first-child {
                margin-left: 0; // Ensures there's no extra margin before the first social icon
            }
            &:last-child {
                margin-right: 2rem;
            }
        }
    }
}

@media (min-width: 992px) {
    .links{
        grid-template-columns: 3fr auto;
        grid-template-areas: "links btns";
        .navLinks{
            grid-area: links;
            justify-self: left;
            width:100%;

            // Set order of links
            #nav-about { order: 1;}
            #nav-systems { order: 2;}
            #nav-upcoming { order: 3;}
            #nav-tutorials { order: 4;}
            #nav-faq { order: 5;}
            div:has(> #account-dropdown){ order: 6;}
            span:has(> #nav-admin) {order:7;}
        }
        .navButtons{
            grid-area: btns;
            justify-self: right;
            button{
                display: inline;   
            }
        }
    }

}


@media (max-width: 991px) {
    .offcanvas-header{
        border-bottom: .2rem solid #F9F9F9;
    }
    .offcanvas-body{ 
        .nav-link{
            margin-left: 1rem;
        }
        .navLinks{
            display:flex;
            flex-direction: column;
            justify-content: center;
            // Set order of links
            div:has(> #account-dropdown){ order: 1;}
            #nav-systems { order: 2;}
            #nav-upcoming { order: 3;}
            #nav-about { order: 4;}
            #nav-tutorials { order: 5;}
            #nav-faq { order: 6;}
            span:has(> #nav-admin) {order:7;}
            // add spacing for icons 
            a > img{
                margin-right: .4rem;
            }
        }
    }

    .links{
        z-index: 9999;
        font-family: "Roboto Bold";
        font-size: 1rem;
        a{
            color: black;
        }
    }
    .icons{
        position: absolute;
        right: 2%;
        top:10px;
    }
    .navbar-brand{
        margin-left: 0 !important;
    }
            
    #dotBadge{
        position: relative;
        top: -10px;
        left: 5px !important; 
        z-index: 10;
        border-radius: 50%;
        width: .5rem;
        height: .5rem;
        text-align: center;
        background: red;
        color: white;
        font-size:medium;
        opacity: 1;
    }

    #footer{
        height: 110px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: "top"
                             "bottom";
        #left-footer{
            grid-area: top;
            justify-self: left;
        }
        #socials{
            grid-area: bottom;
            justify-self: center;
            .navLink {
                margin-left: .2rem;     
                &:first-child {
                    margin-left: .2rem;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        } 
    }
}