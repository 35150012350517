/* styles for the body */
body {
  font-family:"Roboto";
  overflow-x: hidden;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  color:black;
}

.left-align{
  text-align: left;
}
.right-align{
  text-align: right;
}
.center-align{
  text-align: center;
}


