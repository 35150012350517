.tos{
    display: grid;
    justify-self: center;
    .header{
        justify-self: center;
    }
    .content{
        justify-self: center;
        max-width: 80vw;
    }

}